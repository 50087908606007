body, html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    /* The image used */
    background-image: url("assets/room.jpg");

    /* Full height */
    height: 100%;
    width: 100%;
  
    /* Center and scale the image nicely */
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;

    /*hide unwanted white space at the bottom of the page*/
    overflow-y: hidden;

  /* Scrollbar */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(0,0,0,.2);

  --scrollbar-size: .375rem;
  --scrollbar-minlength: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width:550px){
  body {
    background-image: url("assets/room-mobile.jpg");
  }
}
