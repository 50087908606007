
:root {
  --opacity: 0.93;
}

#type-animation {
  position: absolute;
  left: 20%;
  top: 45%;
}

#mobile-type-animation {
  position: absolute;
  left: 20%;
  top: 65%;
  user-select: none;
}

.App {
  text-align: left;
  color: #fff;
  font-size: 36px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

button {
  color: #fff;
  transition: 0.5s;
}
button:hover, button:focus {
  border-color: #fff;
  color: #fff;
}

.raise:hover,
.raise:focus {
  box-shadow: 0.2em 0.2em 0.2em -0.2em #fff;
  transform: translateY(-0.25em) translateX(-0.05em);
}


button {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.35em;
  padding: 0.25em 1em;
}

h1 {
  font-weight: 400;
}

code {
  color: #e4cb58;
  font: inherit;
}

.response-div {
  position: relative;
  top: 20px;
  visibility: visible;
  opacity: 1;
  transition: all .2s;
}

.response-div.off {
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}



.overlay {
  z-index: -10;
  background: #001933;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: fill;
  opacity: var(--opacity);
}


@media screen and (max-width:550px) and (orientation: portrait){
  .bulb-position {
    position: absolute;
    left: 80%;
    top: 3%;
  }
}

@media screen and (min-width:550px) {
  .bulb-position {
    position: absolute;
    left: 70%;
    top: 3%;
  }
}
