*, *::after, *::before {
  box-sizing: border-box;
}

.container {
  margin-top: -30px;
  width: 75px;
  height: 275px;
  animation: bulb-swing 7s infinite ease-in-out;
  transform-origin: top center;
  position: absolute;
  top: 0;
  left: calc(50% - (75px/2));
  z-index: 1;
}

.container.paused {
  animation-play-state: paused;
}

.bulb {
  z-index: 10;
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: absolute;
  top: 200px;
  left: calc(50% - (75px/2));
  background: #FFD700;
  box-shadow: 5px 5px 80px gold, 5px -5px 80px gold, -5px 5px 80px gold, -5px -5px 80px gold;
  transition: all .6s;
}
.bulb::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 50px;
  background: black;
  bottom: 95%;
  left: calc(50% - (35px/2));
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.bulb::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 250px;
  background: black;
  bottom: 100%;
  left: calc(50% - 5px);
}
.bulb .filaments {
  display: block;
  position: absolute;
  width: 2px;
  height: 30px;
  top: 2px;
  left: 28px;
  box-shadow: 17px 0 #fbff00;
  background: #fbff00;
  z-index: -1;
}
.bulb .filaments::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 4px;
  width: 2px;
  height: 12px;
  display: block;
  box-shadow: 4.5px 0 #fbff00, 9px 0 #fbff00;
  background: #fbff00;
  z-index: -1;
}

.bulb.off {
  background: transparent;
  box-shadow: none;
  border: 1px solid black;
  transition: all .1s;
}

.bulb.off .filaments::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 4px;
  width: 2px;
  height: 12px;
  display: block;
  box-shadow: 4.5px 0 black, 9px 0 black;
  background: black;
  transition: all 1s;
}

.filaments.off {
  opacity: 1;
  box-shadow: 17px 0 black;
  background: black;
  transition: all 1s;
}

@keyframes bulb-swing {
  0% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

@keyframes pull-cord-swing {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}

@keyframes pull-animation-low {
  0% {
    height: 350px;
  }

  70% {
    height: 380px;
  }

  100% {
    height: 370px;
  }

}

@keyframes pull-animation-high {
  0% {
    height: 370px;
  }

  50% {
    height: 390px;
  }

  100% {
    height: 350px;
  }

}


.floor {
  position: absolute;
  background: #000c19;
  height: 200px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.floor .shadow {
    position: absolute;
  background: #524c11;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  box-shadow: 20px 20px 100px gold, -20px 20px 100px gold, 20px -20px 100px gold, -20px -20px 100px gold;
  animation: shadow-swing 3s infinite ease-in-out;
}

.shadow.off {
  background: #000c19;
  box-shadow: none;
}

@keyframes shadow-swing {
  0% {
    transform: translateX(100px);
  }
  50% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(100px);
  }
}

.pull-cord-container {
  animation: pull-cord-swing 4s infinite ease-in-out;
  cursor: pointer;
  position: absolute;
  width: 30px;
  top: 0px;
  left: calc(50% - 60px);
  margin-top: -30px;
  padding-left: 17px;
  z-index: 2;
}

.pull-cord {
  margin-top: -30px;
  content: "";
  width: 7px;
  
  height: 350px;
  background: rgba(43, 43, 43, 0.6);
  top: 0px;
  z-index: -2;
  cursor: pointer;
  /* transition: all 1s; */
}
.pull-cord.low {
  -webkit-animation: pull-animation-low .25s ease-in forwards;
          animation: pull-animation-low .25s ease-in forwards;
}

.pull-cord.high {
  -webkit-animation: pull-animation-high .25s ease-in forwards;
          animation: pull-animation-high .25s ease-in forwards;
}


.gullumuxhi {
  width: 12px;
  height: 20px;
  background: rgb(78, 75, 75);
  top: 300px;
  z-index: -1;
  border-radius: 30px;
  margin-left: -3px;
}